import { BULLET_CARDS } from "data/home/bulletCardGrid";
import { CONTENT_CARDS_WITH_TITLE } from "data/home/contentCards";
import { FULL_WIDTH_CARDS } from "data/home/fullWidthCards";
import { HERO_WITH_IMAGE_PROPS } from "data/home/hero";
import { LOGO_ROWS } from "data/home/logoScroller";
import { TABS } from "data/home/tabbedInfo";
import { LINKS } from "data/preFooter";
import { TESTIMONIALS } from "data/testimonials";
import Head from "next/head";

import Section from "components/atoms/Section";
import Typography from "components/atoms/Typography";
import BulletCard from "components/molecules/BulletCard";
import FullWidthCard from "components/molecules/FullWidthCard";
import CardSlider from "components/organisms/CardSlider";
import LogoScroller from "components/organisms/LogoScroller";
import PreFooter from "components/organisms/PreFooter";
import TabbedInfo from "components/organisms/TabbedInfo";
import PhotoCard from "components/organisms/TabbedInfo/PhotoCard";
import Quote from "components/organisms/TabbedInfo/Quote";
import TestimonialsGallery from "components/organisms/TestimonialsGallery";
import ContentCardsWithTitle from "components/templates/ContentCardsWithTitle";
import HeroWithImage from "components/templates/HeroWithImage";

import { SVPage } from "types/theme.types";

const Home: SVPage = () => {
  return (
    <>
      <Head>
        <link rel="canonical" href="https://sv.academy/" key="canonical" />
      </Head>
      <HeroWithImage {...HERO_WITH_IMAGE_PROPS} />
      <Section className="pt-8 pb-9 md:py-12">
        <Typography.H2 className="text-center text-green-gray mx-auto md:px-11">
          Find our grads at some of the world’s most exciting companies.
        </Typography.H2>
      </Section>
      <div className="w-full">
        <LogoScroller logos={LOGO_ROWS} />
      </div>
      <Section className="mt-12 md:mt-24">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12">
          <div>
            <Typography.H4 className="text-green-black mb-6 md:mb-12">
              We develop high-potential talent so revenue teams can scale
              quickly
            </Typography.H4>
            <Typography.Body className="text-green-black">
              SV Academy prepares job seekers from different backgrounds for
              entry level positions in Sales or Business Development in weeks.
              Graduates start new careers sooner and employers hire top talent
              faster.
            </Typography.Body>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-11">
            {BULLET_CARDS.map((card, index) => (
              <BulletCard key={index} {...card} />
            ))}
          </div>
        </div>
      </Section>

      <ContentCardsWithTitle {...CONTENT_CARDS_WITH_TITLE} />

      <Section className="bg-warm-light pt-12 pb-8 md:py-12">
        <Typography.H1 className="text-center text-sv-red mb-6 md:mb-14 mx-auto md:px-11">
          Lives and teams: Transformed
        </Typography.H1>

        <div className="hidden md:block">
          <TabbedInfo tabs={TABS} />
        </div>

        <div className="md:hidden px-4">
          <CardSlider numberOfPills={TABS.length}>
            <CardSlider.Pills />
            <CardSlider.Body slideSize="3/4">
              {TABS.map(({ body }, index) => (
                <CardSlider.Slide key={index}>
                  <div className="px-1">
                    <PhotoCard content={body.photoCard} />
                    <div className="mt-4">
                      <Quote content={body.quote} />
                    </div>
                  </div>
                </CardSlider.Slide>
              ))}
            </CardSlider.Body>
          </CardSlider>
          <div className="grid grid-cols-1 m-1 gap-4 mt-4">
            {TABS.map(({ head }, index) => (
              <BulletCard
                key={index}
                bg="bg-lighter-gray"
                color="text-green-gray"
                icon={head.icon}
                paragraph={head.label}
              />
            ))}
          </div>
        </div>
      </Section>
      <Section className="bg-warm-light pt-9 md:pt-12 pb-4 md:pb-24">
        <TestimonialsGallery testimonials={TESTIMONIALS} />
      </Section>

      <Section className="pt-12 pb-4 md:py-24">
        <Typography.H2 className="text-center text-green-gray mx-auto md:px-11">
          Join us in our mission to generate $100B in wealth expansion for
          underrepresented job seekers
        </Typography.H2>
        <div className="mt-6 md:mt-12 space-y-4 md:space-y-6">
          {FULL_WIDTH_CARDS.map((card, index) => (
            <FullWidthCard key={index} {...card} />
          ))}
        </div>
      </Section>
      <PreFooter links={LINKS} />
    </>
  );
};

Home.generatePageName = () => "Home";
Home.generateExternalPageName = () =>
  "SV Academy | Lives and Teams: Transformed";
Home.generateExternalDescription = () =>
  "We elevate talented individuals from different backgrounds and connect them with trailblazing companies to launch their careers.";

export default Home;
