export const BULLET_CARDS = [
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-lightning",
    paragraph: "We intensively train job seekers in as little as 4 weeks",
  },
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-overlap",
    paragraph:
      "We match job seekers within 24 hours after a hiring team reaches out",
  },
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-briefcase",
    paragraph:
      "Job seekers can sign offers within days of finishing the program",
  },
  {
    bg: "bg-warm-light",
    color: "text-green-gray",
    icon: "fak fa-sv-compass",
    paragraph:
      "We support managers and hires with 6 months of professional coaching",
  },
];
