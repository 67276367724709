import clsx from "clsx";
import Image from "next/image";

import Typography from "components/atoms/Typography";

import { BackgroundColors, TextColors } from "types/theme.types";

export interface Props {
  content: {
    alt: string;
    author: string;
    bg: BackgroundColors;
    color: TextColors;
    label: string;
    logoUrl: string;
    quote: string;
    size?: string;
  };
  className?: string;
}

export default function Quote(props: Props) {
  const { content } = props;

  return (
    <div
      className={clsx(
        "p-6 md:p-8",
        "rounded-2xl",
        "relative",
        content.bg,
        props.className
      )}
    >
      <Typography.H5 color={content.color} className={content.size}>
        {content.quote}
      </Typography.H5>
      <Typography.Body color={content.color} className="mt-3 mb-6 opacity-70">
        <b>{content.author}</b>
        {content.label}
      </Typography.Body>
      <div className="h-6 md:h-9 relative">
        <Image
          layout="fill"
          objectFit="contain"
          objectPosition="left"
          src={content.logoUrl}
          alt={content.alt}
        />
      </div>
    </div>
  );
}
