import HeroWithImage from "components/templates/HeroWithImage";

export const HERO_WITH_IMAGE_PROPS: Readonly<
  React.ComponentProps<typeof HeroWithImage>
> = {
  color: "text-sv-red",
  title: "Pivot into a high-growth business career",
  subtitle:
    "SV Academy has helped thousands of former servers, teachers, retail workers, and more translate their people skills into rewarding, lucrative business careers — at a fraction of the cost of business school. Are you next?",
  image: {
    src: "/images/home/hero-bg.webp",
    alt: "Hero SV Community Desktop",
    priority: true,
  },
  mobileImage: {
    src: "/images/home/hero-bg-mobile.webp",
    alt: "Hero SV Community Mobile",
    priority: true,
  },
};
